<template>
  <span>
    <b-btn
      v-b-tooltip.hover
      :title="`Atualizar a ${tipoRotina}`"
      variant="none"
      class="btn-none m-0 icone-acao p-0"
      @click="exibirModal ? openModalCreditos() : atualizar()"
    >
      <font-awesome-icon
        :icon="faRefresh"
        class="text-primary"
      />
      <loading
        :show-cancel-button="false"
        :is-loading="isLoading"
        :titulo-carregamento="'Aguarde!'"
        :descricao="`Estamos atualizando a ${tipoRotina}.`"
      />
    </b-btn>
    <consumo-credito
      ref="atualizarManual"
      :tipo-rotina="tipoRotina"
      @atualizar="atualizar()"
      @fechar="fecharModalAtualizacao()"
    />
  </span>
</template>

<script>
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    ConsumoCredito: () => import('@/app/shared/components/modal-consumo-credito/ModalConsumoCredito.vue'),
  },
  props: {
    tipoRotina: {
      type: String,
      required: true,
    },
    enviarAtualizacao: {
      type: Function,
      required: true,
    },
    infoExtra: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exibirModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      faRefresh,
      isLoading: false,
    }
  },
  methods: {
    atualizar() {
      this.isLoading = true
      this.$refs.atualizarManual.$refs.consumoCredito.hide()
      this.enviarAtualizacao(this.infoExtra)
        .then(payload => {
          this.$emit('atualizar-grid')
          this.verificarRetornos(payload.data)
        })
        .catch(err => {
          this.$emit('atualizar-grid')
          this.verificarRetornos(err.response.data.message)
          throw err
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    verificarRetornos(result) {
      if (result === 1) {
        this.$sucesso(`${this.tipoRotina} atualizada com sucesso!`)
      } else if (result === 3) {
        this.openModalErro('Limite atingido', `O limite de emissão diária da ${this.tipoRotina} foi atingido para todos os certificados válidos disponíveis. Aguarde a liberação de novas consultas.`)
      } else if (result === 4) {
        this.openModalErro('Nenhum certificado encontrado', 'Nenhum certificado válido foi encontrado. A atualização manual não pode ser realizada.')
      } else if (result === 5) {
        this.$erro('Saldo insuficiente para concluir a operação.')
      } else if (result === 6) {
        this.$erro('Procuração eletrônica - Empresa apresenta erro com a procuração eletrônica. Verifique e corrija para evitar cobranças indesejadas e erros nas atualizações das rotinas.')
      } else if (result instanceof String && result.includes('Não possui máquina disponível tempo máximo excedido')) {
        this.$aviso('Não foi possível processar sua solicitação, tente novamente em alguns instantes!')
      } else if (result instanceof String && result.includes('Requisição inválida.')) {
        this.$aviso('Serviço indisponível no momento, por favor, tente novamente em breve!')
      } else if (result) {
        this.$erro(`Não foi possível fazer a atualização. Tente novamente mais tarde!. Erro: ${result}`)
      } else {
        this.$erro('Houve um problema com a atualização. Tente novamente mais tarde!')
      }
    },
    openModalCreditos() {
      this.$emit('stacking', false)
      this.$nextTick(() => {
        this.$refs.atualizarManual.$refs.consumoCredito.show()
      })
    },
    fecharModalAtualizacao() {
      this.$refs.atualizarManual.$refs.consumoCredito.hide()
      this.$emit('stacking', true)
    },
    openModalErro(title, html) {
      this.$swal({
        title: `<div class="text-danger">${title}!</div>`,
        html: `<div class="text-primary">${html}</div>`,
        icon: 'warning',
        iconColor: '#ea5455',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-outline-danger mr-50',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
